<template>
  <div class="pharmen">
    <h1 class="d-flex justify-center">Kategorier</h1>
    <v-container v-for="(src, cat) in $store.state.images_by_main_categories"
                 :key="src">
      <router-link :to="{ name: 'maincat', params: { main_category: cat } }"
                   style="text-decoration: none;">
        <v-img :src="src"
               height="150px">
          <v-container class="d-flex justify-end">
            <h1 class="white--text">{{ cat }}</h1>
          </v-container>
        </v-img>
      </router-link>
    </v-container>
    <!--     <v-img src="../assets/feberochvärk.webp" height="150px"></v-img>
    <v-img src="../assets/händerochfötter.webp" height="150px"></v-img> -->
  </div>
</template>

<script>
export default {
}
</script>