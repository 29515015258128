<template>
  <div class="pharmen">
    <v-breadcrumbs :items="getBreadcrumbs()"
                   large></v-breadcrumbs>
    <v-row>
      <v-col class="d-flex justify-center">
        <h1>{{ main_category }}</h1>
      </v-col>
    </v-row>
    <v-img :src="$store.state.images_by_main_categories[main_category]"></v-img>
    <v-container>
      <v-row justify="space-around">
        <v-col cols="8"
               sm="8"
               md="4"
               lg="3"
               xl="3"
               v-for="(subcat, index) in subcats()"
               :key="index"
               class="d-flex justify-center">
          <v-sheet class="test rounded-circle mb-16">
            <router-link :to="{ name: 'subcat1', params: { main_category: main_category, subcategory_1: subcat } }" style="text-decoration: none;">
              <v-container class="test2 d-flex align-center justify-center">
                <v-img max-width="50%"
                       :src="$store.state.images_by_subcategories_1[subcat]">
                </v-img>
              </v-container>
              <v-container class="datext d-flex align-center justify-center">
                <h1 style="color: black;">{{ subcat }}</h1>
              </v-container>
            </router-link>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.datext {
  display: flex;
  text-align: center;
  align-self: center;
  line-height: 200%;
}

.test {
  position: relative;
  width: 80%;
  height: 0;
  padding-bottom: 80%;
  background-color: #e0ebd1;
}

.test2 {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
}
</style>

<script>
import Recommendations from '@/components/LandingPageRecommendations.vue';
export default {
  props: ["main_category"],
  methods: {
    getBreadcrumbs() {
      return [
        { text: 'Kategorier', link: true, exact: true, to: { name: 'cats' } },
        { text: String(this.main_category) },
      ]
    },
    subcats() {
      const items = this.$store.getters.items_by_main_category(this.main_category);
      return [...new Set(items.map(el => el.subcategory_1))];
    }

  },
  components: {
    Recommendations
  }
}
</script>