import Vue from 'vue'
import VueRouter from 'vue-router'
import LandingView from '../views/LandingView.vue'
import ProductView from '../views/ProductView.vue'
import CheckoutView from '../views/CheckoutView.vue'
import MainCategoryView from '../views/MainCategoryView.vue'
import Subcategory_1View from '../views/Subcategory_1View.vue'
import CategoriesView from '../views/CategoriesView.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LandingView
  },
  {
    path: '/product-:id',
    name: 'prod',
    component: ProductView,
    props: true
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView
  },
  {
    path: '/kategorier',
    name: 'cats',
    component: CategoriesView,
  },
  {
    path: '/:main_category',
    name: 'maincat',
    component: MainCategoryView,
    props: true,
  },
  {
    path: '/:main_category/:subcategory_1',
    name: 'subcat1',
    component: Subcategory_1View,
    props: true,
  },
]

const router = new VueRouter({
  routes
})

export default router
