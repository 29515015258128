<template>
  <div class="pharmen">
    <h1 class="d-flex justify-center">
    <v-img src="../assets/ffbanner.png"  style="margin: 15px">
    </v-img>
    </h1>
    <h1 class="d-flex justify-center">
      Rekommendationer för dig
    </h1>
    <RecommendationList :items="recommendations" />
    <v-divider></v-divider>
    <router-link :to="{ name: 'cats' }" style="text-decoration: none;">
      <v-sheet color="#f4faec">

        <v-container>
          <v-row class="align-start">
            <v-col class="d-flex justify-center"
            cols="12">
            <v-container>
              <v-col cols="12" class="d-flex justify-center">
                <h1>Bläddra bland kategorier</h1>
              </v-col>
              <v-img style="border-radius: 50px">
                
                <v-row no-gutters>
                  <v-col v-for="(src, _) in $store.state.images_by_main_categories" :key="src" cols="6">
                    <v-img :src="src" height="70px"></v-img>
                  </v-col>
                </v-row>
              </v-img>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
    </router-link>
    <!--    <RecommendationList :items="recommendations"
                        :interval_size="5" />
    <RecommendationList :items="recommendations"
                        :interval_size="5" /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import RecommendationList from '@/components/LandingPageRecommendations.vue';
export default {
  data: () => ({
    recommendations: [],
    reload: 0,
    images: [
      { title: 'Feber och Vätu6yrk', src: require('../assets/feberochvark.webp') },
      { title: 'Händer och Fötter', src: require('../assets/händerochfötter.webp') },
      { title: 'Hudvård', src: require('../assets/hudvård.jpeg') },
      { title: 'Mun och tänder', src: require('../assets/munochtänder.webp') },
      { title: 'Näsa, Ögon, öron', src: require('../assets/näsaögonöron.webp') },
      { title: 'Vitaminer och Kosttillskott', src: require('../assets/kosttillskottochvitaminer.webp')},
      { title: 'Intim', src: require('../assets/intim.webp')},
      { title: 'Sår, bett och stick', src: require('../assets/sårbettstick.jpg')}
    ]
  }),
  computed: {
    ...mapGetters({
      opts: 'fetcher_opts',
    })

  },
  components: {
    RecommendationList
  },

  methods: {
    get_recommendation() {
      return this.recommendations;
    }
  },

  watch: {
    opts: {
      deep: true,
      handler: async function (options) {
        var temp_options = structuredClone(options);
        temp_options.body = JSON.stringify(temp_options.body);
        this.recommendations = [];
        const url = 'https://d16.infobaleen.com/api/v1/api-profiles/496/recommendations/user-to-items?use-cors';
        fetch(url, temp_options)
          .then(response => response.json())
          .then(data => {
            for (const el of data) {
              this.$store.dispatch('add_product_to_state', el)
                .then(res => (this.recommendations.push(res)))
            }
          })
      },
      immediate: true,
    }
  },
}
</script>

<style scoped>
::v-deep(.productText) {
  font-size: 100%;
}
</style>