<template>
  <div class="pharmen mt-1" v-if="items.length > 0">
    <vue-horizontal scroll ref="myVueHorizontal">
      <section v-for="item in items" :key="item.Id">
        <v-card class="pharmen"
                flat
                color="transparent">
          <v-card class="mx-auto">
            <v-card-actions>
              <v-row>
                <v-btn icon
                       text
                       @click="$store.commit('change_cart', { product: item, n: 1 })">
                  <v-icon v-bind="iconSize">
                    mdi-cart-plus
                  </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="red"
                       icon
                       @click="item.toggle_liked()">
                  <v-icon v-bind="iconSize"
                          v-if="item.liked">
                    mdi-heart
                  </v-icon>
                  <v-icon v-bind="iconSize"
                          v-else>
                    mdi-heart-outline
                  </v-icon>
                </v-btn>
              </v-row>
            </v-card-actions>
            <router-link :to="'product-' + item.Id">
              <v-card-actions>
                <v-img :height="height"
                       :src="item.image_link"
                       contain></v-img>
              </v-card-actions>
            </router-link>
          </v-card>
          <v-card-text class="pa-0 productText">
            <div style="min-height: 34.4px">
              <span class="productTitle">{{ item.title }}</span>
            </div>
            <div class="pt-1 d-flex justify-end">
              <span v-if="item.sale_discount > 0 && item.sale_price != NaN"
                    class="oldPrice">{{ item.price }};-</span>
              <span class="newPrice">{{ item.sale_price }};-</span>
            </div>
          </v-card-text>
        </v-card>
      </section>
    </vue-horizontal>
  </div>
</template>

<script>
import VueHorizontal from 'vue-horizontal'
export default {
  props: {
    items: [],
    options: {},
  },
  data: () => ({
  }),
  components: {
    VueHorizontal
  },
  computed: {
    iconSize() {
      var sizes = {
        'x-small': false,
        'small': false,
        'large': false,
        'x-large': false,
      }
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': sizes['x-small'] = true; break
        case 'sm': sizes['small'] = true; break
        case 'md': sizes['small'] = true; break
        case 'lg': sizes['small'] = true; break
        case 'xl': sizes['small'] = true
      }
      return sizes;
    },
    height() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '50px'
        case 'sm': return '80px'
        case 'md': return '90px'
        case 'lg': return '110px'
        case 'xl': return '130px'
        default: return '80px'
      }
    },
    width() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '60px'
        case 'sm': return '100px'
        case 'md': return '120px'
        case 'lg': return '180px'
        case 'xl': return '200px'
        default: return '140px'
      }
    },
  },
}
</script>

<style scoped>

section {
  padding: 16px 6px;
  width: 151px;
  margin-right: 24px;
}

/* @media (min-width: 640px) {
  section {
    width: calc((100% - (24px)) / 2);
  }
}

@media (min-width: 768px) {
  section {
    width: calc((100% - (24px)) / 2);
  }
}

@media (min-width: 1024px) {
  section {
    width: calc((100% - (24px)) / 2);
  }
}

@media (min-width: 1280px) {
  section {
    width: calc((100% - (24px)) / 2);
  }
} */
.item {
  padding: 16px 24px;
  border-radius: 3px;
  background: #f5f5f5;
}

.productText {
  font-size: 100%;
  word-break: normal;
  width: 100%;
  line-height: 100%;
  margin-top: 5px;
  bottom: 0px;
}

.productTitle {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>