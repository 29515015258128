<template>
  <div>
    <v-list-item class="tile">
      <v-list-item-content>
        <v-list-item-title class="text-h4">Meny</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title style="font-size: 120%;"> Handla efter kategori</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list>
      <v-list-group v-for="(title, index) in $store.state.categories"
                    :key="index"
                    no-action>
        <template v-slot:activator>
          <v-list-item-title>{{ title[0] }}</v-list-item-title>
        </template>
        
        <v-list-item class="pl-8" v-for="(subcat, idx) in title[1]" :key="idx" link>
          <!-- :to="title[0]+'/'+subcat"  -->
          <router-link :to="{ name: 'subcat1', params: { main_category: title[0], subcategory_1: subcat }}" style="text-decoration: none;">
            <v-list-item-content>
              <v-list-item-subtitle>
                {{subcat}}
              </v-list-item-subtitle>
            </v-list-item-content>
          </router-link>
          </v-list-item>
       <!--  <v-list-item-content v-for="(subcat, idx) in title[1]"
                             :key="idx">
          <v-list-item-subtitle>
            {{ subcat }}
          </v-list-item-subtitle>
        </v-list-item-content> -->
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
export default {
  data: () => ({
    titles: [
      /* 'Allergi',
      'Ansiktsvård',
      'Barn & Föräldrar',
      'Djur',
      'Förkylning & Feber',
      'Hem & Hushåll',
      'Hudvård',
      'Händer & Fötter',
      'Hårvård',
      'Intim',
      'Kost & Hälsa',
      'Mage',
      'Makeup',
      'Motion & Hälsa',
      'Mun & Tänder',
      'Presenter',
      'Slut Röka',
      'Sår, Bett & Stick',
      'Sömn, Stress & Oro',
      'Vitaminer & Mineraler',
      'Värk',
      'Ögon & Öron' */
    ],
  }),
  created() {
    const catalogue = this.$store.state.all_products;
    const main_categories = [...new Set(catalogue.map(el => el.main_category))];
    for (const main_category of main_categories) {
      const main_cat_products = catalogue.filter(el => { return el.main_category == main_category });

      this.titles[main_category] = [...new Set(main_cat_products.map(el => el.subcategory_1))];
      console.log(this.titles[main_category])
    }
    console.log(Object.entries(this.titles))
  }
}
</script>