<template>
  <div>
    <v-container class="pharmen">
      <v-row align="center"
             justify="center">
        <h1>KASSA</h1>
      </v-row>
      <v-row>
        <v-divider></v-divider>
      </v-row>
      <v-row>
        <v-col cols="12"
               xs="12"
               md="6"
               lg="6"
               xl="6">
          <v-list two-line
                  dense
                  max-height="400"
                  class="py-0 rounded">
            <template v-if="$store.getters.carted_count > 0">
              <div v-for="item in $store.getters.carted_items"
                   :key="item.title"
                   style="background-color: #e0ebd1;">

                <v-list-item class="pb-0">


                  <v-list-item-avatar rounded="0">
                    <v-img contain
                           :src="item.image_link"> </v-img>
                  </v-list-item-avatar>
                  <div>
                    <v-list-item-content>
                      <v-list-item-action-text v-text="item.title"></v-list-item-action-text>
                      <v-list-item-subtitle class="newPrice red--text">
                        {{ item.sale_price }};-
                      </v-list-item-subtitle>

                    </v-list-item-content>
                  </div>
                  <v-spacer></v-spacer>
                  <div class="d-flex">
                    <v-text-field prepend-icon="mdi-minus"
                                  append-outer-icon="mdi-plus"
                                  v-model="item.carted"
                                  class="centered-input some-style"
                                  hide-details
                                  @click:prepend="$store.commit('change_cart', { product: item, n: -1 })"
                                  @click:append-outer="$store.commit('change_cart', { product: item, n: 1 })"></v-text-field>
                  </div>
                  <v-btn icon
                         style="position:absolute; right: 5px; top: -5px; transform: scale(0.7);"
                         @click.stop="$store.commit('change_cart', { product: item, n: -item.carted })">
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>




                </v-list-item>
                <v-list-item style="max-height: 4px; min-height: 4px;">
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </template>
            <template v-else>
              <v-list-item>
                <v-list-item-title class="text-center">Din varukorg är tom!</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
          <v-row justify="end"
                 class="ma-0">
            <v-container style="max-width: 70%;"
                         class="ma-4">
              <v-row>
                <p class="ma-0"
                   style="font-size: 110%">MOMS: </p>
                <v-spacer></v-spacer>
                <p class="ma-0"
                   style="font-size: 110%">{{ $store.getters.taxed_sum }};-
                </p>
              </v-row>
              <v-row>
                <p class="ma-0"
                   style="font-size: 110%">FRAKT: </p>
                <v-spacer></v-spacer>
                <p class="ma-0"
                   style="font-size: 110%">
                  {{ String($store.getters.shipping_fee) }};-
                </p>
              </v-row>
              <v-row v-if="coupon">
                <p class="ma-0 red--text"
                   style="font-size: 110%">VÄRDECHECK: </p>
                <v-spacer></v-spacer>
                <p class="ma-0 red--text"
                   style="font-size: 110%">{{ String(valueCheck) }};-</p>
              </v-row>
              <v-row class="pt-2 pb-2">
                <v-divider color="black"></v-divider>
              </v-row>
              <v-row>
                <h1 class="ma-0"
                    style="font-size: 110%">TOTALT: </h1>
                <v-spacer></v-spacer>
                <h1 class="ma-0"
                    style="font-size: 110%">{{ String(totalPrice()) }};- </h1>
              </v-row>
              <v-row>
                <v-expansion-panels flat>

                  <v-expansion-panel style="background-color: #e0ebd1;">
                    <v-expansion-panel-header class=""
                                              expand-icon="">

                      Har du ett presentkort eller rabattkod?
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0">
                      <v-form>

                        <v-text-field class="pt-n4 mt-n2"
                                      style="max-width: 500px; width: 500px;"
                                      v-model="couponInput"
                                      outlined
                                      filled
                                      label="Skriv in din kod"
                                      hint="Använd INFOBALEEN22 för 20 kr rabatt"
                                      @keyup.enter="validCoupon(couponInput)">
                          <template v-slot:append-outer>
                            <v-btn color="#cfe1b7"
                                   @click.stop="validCoupon(couponInput)">Lägg till</v-btn>
                          </template>
                        </v-text-field>
                      </v-form>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-row>
            </v-container>
          </v-row>
        </v-col>
        <v-col cols="12"
               xs="12"
               md="6"
               lg="6"
               xl="6">
          <v-card color="#e0ebd1"
                  flat>
            <v-card-actions>

              <CheckoutForm />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="$store.getters.carted_count">
          <h1>Komplettera? </h1>
          <Recommendations :items="recommendations"/>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CheckoutForm from '@/components/CheckoutForm.vue';
import Recommendations from '@/components/LandingPageRecommendations.vue';
export default {
  data: () => ({
    valueCheck: 20,
    coupon: false,
    couponInput: "",
    recommendedProducts: [],
    asd: 0,
    recommendations: []
  }),
  components: {
    CheckoutForm,
    Recommendations
  },
  computed: {
    ...mapGetters({
      opts: 'fetcher_opts',
    }),
    cartedCount() {
      console.log(this.$store.state.api_recommendations.list1)
      return this.$store.state.api_recommendations.list1;
    }
  },
  methods: {
    validCoupon: function () {
      if (this.couponInput === "INFOBALEEN22") {
        this.coupon = true
        this.couponInput = ""
        return true
      }
      return false
    },
    totalPrice: function () {
      var total = Number(this.$store.getters.cart_sum) + Number(this.$store.getters.shipping_fee);
      if (this.coupon) {
        total -= this.valueCheck;
      }
      if (total < 0) {
        return 0;
      }
      else {
        return total;
      }
    },
    getRecommendations: function () {
      return this.$store.getters.fetchFromAPI;
    }
  },
  watch: {
    opts: {
      deep: true,
      handler: async function (options) {
        var temp_options = structuredClone(options);
        temp_options.body = JSON.stringify(temp_options.body);
        this.recommendations = [];
        const url = 'https://d16.infobaleen.com/api/v1/api-profiles/500/recommendations/user-to-items?use-cors';
        fetch(url, temp_options)
          .then(response => response.json())
          .then(data => {
            for (const el of data) {
              this.$store.dispatch('add_product_to_state', el)
                .then(res => (this.recommendations.push(res)))
            }
          })
      },
      immediate: true,
    }
  },

}
</script>

<style scoped>
.centered-input>>>input {
  text-align: center;
}

.some-style>>>.v-input__slot::before {
  border-style: none !important;
}

.v-text-field {

  display: flex;
  text-decoration: none !important;
  transform: scale(0.8);
  max-width: 100px;
  width: 100px;
}

.v-card {
  overflow-y: hidden;
}

.v-card-title {
  overflow-y: scroll;
}

/* Sätt v-card till scroll och ta bort från v-list om man vill att 
   tool-baren ska scrolla ner */

.v-list {
  overflow-y: scroll;
}
</style>

<style scoped>
.v-expansion-panel-content>>>.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
