<template>
  <div class="pharmen">
    <v-breadcrumbs :items="getBreadcrumbs()" large></v-breadcrumbs>
    <h1>{{ subcategory_1 }}</h1>
    <Recommendations :items="$store.getters.items_by_subcategory_1(subcategory_1)"/>
  </div>
</template>

<script>
import Recommendations from '@/components/LandingPageRecommendations.vue';
export default {
  props: ["subcategory_1", "main_category"],
  components: {
    Recommendations
  },
  methods: {
    getBreadcrumbs() {
      return [
        {text: 'Kategorier', link: true, exact: true, to: {name: 'cats'}}, 
        {text: String(this.main_category), link: true, exact: true, to: {name: 'maincat', params: {main_category: this.main_category}}},
        {text: String(this.subcategory_1)}
    ]
    }
  },
}
</script>