<template>
  <div class="pharmen">
    <v-card>
      <v-container>
        <v-row>
          <v-col cols="12"
                 sm="12"
                 md="5"
                 lg="5"
                 xl="5"
                 class="d-flex align-start justify-end"
                 style="position: absolute; padding-top: 4px; padding-right: 4px;">
            <v-btn color="red"
                   icon
                   @click="product.toggle_liked()">
              <v-icon size="30"
                      v-if="product.liked">
                mdi-heart
              </v-icon>
              <v-icon size="30"
                      v-else>
                mdi-heart-outline
              </v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12"
                 sm="12"
                 md="5"
                 lg="5"
                 xl="5"
                 class="d-flex align-start justify-center">
            <v-card-actions>
              <v-img max-height="230"
                     :src="product.image_link"
                     contain></v-img>
            </v-card-actions>
          </v-col>
          <v-col>
            <v-card-title>{{ product.title }}</v-card-title>
            <v-card-actions class="pl-4 mt-n6">
              <v-container>
                <v-row>
                  <v-rating dense
                            :value="product.rating"
                            background-color="grey"
                            color="pink lighten-4"
                            readonly
                            small
                            large></v-rating>
                </v-row>
                <v-row>
                  <v-card-title v-if="product.sale_discount > 0" class="newPrice" style="display: flex; align-items: baseline;">
                    <span>
                      {{product.sale_price}};- &nbsp;
                    </span>
                    <span style="font-size: 50%; color: black;">
                      (ord. pris: {{product.price}};-)
                    </span>
                  </v-card-title>
                  <v-card-title v-else class="newPrice black--text">
                    {{product.price}};-
                  </v-card-title>
                </v-row>
                <v-row dense
                       class="pa-0">
                  <v-col cols="4"
                         md="3"
                         lg="3"
                         xl="3"
                         class="pt-0 pr-0 pb-0 mt-0">
                    <v-text-field prepend-icon="mdi-minus"
                                  hide-details="auto"
                                  append-outer-icon="mdi-plus"
                                  class="pt-0 mt-0 centered-input some-style"
                                  :value="cartingCount"
                                  @click:prepend="cartingCount--"
                                  @click:append-outer="cartingCount++"
                                  :rules="[atleastOneProduct, isInteger(cartingCount)]"
                                  v-model="cartingCount"></v-text-field>
                  </v-col>
                  <v-col class="pa-0">
                    <v-btn :ripple="{ class: 'success--text' }"
                           outlined
                           text
                           class="pa-0 pl-2"
                           @click="$store.commit('change_cart', { product: product, n: cartingCount })"
                           :loading="loading">
                      Lägg till i varukorg &nbsp; &nbsp; &nbsp;
                      <v-icon>
                        mdi-cart-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
            <v-divider class="ml-3"></v-divider>
            <v-card-text class="item">
              <LoremIpsum :class="{ description: true, 'description--hidden': showMore }"
                          add="1p"></LoremIpsum>
              <v-btn text
                     class="show-more-btn"
                     @click="showMore = !showMore">
                <div v-if="showMore">
                  <span>Visa Mer</span>
                  <v-icon>mdi-chevron-down</v-icon>
                </div>
                <div v-else>
                  <span>Visa Mindre</span>
                  <v-icon>mdi-chevron-up</v-icon>
                </div>
              </v-btn>
            </v-card-text>
          </v-col>
        </v-row>
        <v-expansion-panels accordion flat multiple>
          <v-expansion-panel v-for="eh in expansionHeaders" :key="eh">
            <v-expansion-panel-header>{{ eh }}</v-expansion-panel-header>
            <v-expansion-panel-content>
              <ul v-for="n in 5" :key="n">
                <li>
                  <LoremIpsum add="2s"></LoremIpsum>
                </li>
              </ul>
            </v-expansion-panel-content>
            <v-divider></v-divider>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import LoremIpsum from 'vue-lorem-ipsum';
export default {
  data: () => ({
    loading: false,
    showMore: true,
    cartingCount: 1,
    expansionHeaders: [
        'Instruktioner',
        'Innehåll',
        'Produktfakta',
        'Produktomdömen'
      ],
  }),
  props: {
    product: Object,
  },
  components: {
    LoremIpsum
  },
  methods: {
    atleastOneProduct() {
      return this.cartingCount > 0;
    },
    isInteger: function (value) {
      return !isNaN(value) && parseInt(Number(value)) == value && !isNaN(parseInt(value, 10));
    },
    cartAndSetPopup (product) {
      product.addToCart (this.cartingCount);
      this.cartingCount = 1;
      this.loading = true;
      setTimeout (() => {
        this.loading = false;
      }, 500)
    }
  }
}
</script>

<style scoped>
.centered-input>>>input {
  text-align: center;
}
.some-style>>>.v-input__slot::before {
  border-style: none !important;
}
.v-text-field {

display: flex;
max-width: 100px;
text-decoration: none !important;
}
</style>

<style scoped lang="scss">
  .show-more-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .description--hidden {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    /* number of lines to show */
    -webkit-box-orient: vertical;
  }
</style>
