<template>
  <div class="pharmen">
    <v-breadcrumbs :items="getBreadcrumbs()" large></v-breadcrumbs>
    <ProductDisplay :product="product" />
    <h1 class="ml-2 pt-4 mb-n8">Liknande produkter</h1>
    <Recommendations title="LIKNANDE PRODUKTER"
                     :items="product_recommendations" />
    <v-divider></v-divider>
    <h1 class="ml-2 pt-4 mb-n8">Andra köpte även</h1>
    <Recommendations title="ANDRA KÖPTE ÄVEN"
                     :items="others_bought" />
  </div>
</template>
 
<script>
import { mapGetters } from 'vuex';
import Recommendations from '@/components/LandingPageRecommendations.vue';
import ProductDisplay from '@/components/ProductDisplay.vue';
export default {
  props: ["id"],
  data: () => ({
    product: Object,
    product_recommendations: [],
    others_bought: [],
  }),
  methods: {
    getBreadcrumbs () {
      return [
        {text: this.product.main_category, link: true, exact: true, to: {name: 'maincat', params: {main_category: this.product.main_category}}},
        {text: this.product.subcategory_1, link: true, exact: true, to: {name: 'subcat1', params: {main_category: this.product.main_category, subcategory_1: this.product.subcategory_1}}},
        {text: this.product.subcategory_2}
      ]
    }
  },
  computed: {
    ...mapGetters({
      opts: 'fetcher_opts',
    })
  },
  watch: {
    id: {
      handler(t) {
        console.log(t);
        this.product = this.$store.getters.get_item_by_id(t);
        const options = this.$store.getters.fetcher_opts;
        var temp_options = structuredClone(options);
        temp_options.body.Items = [String(this.product.Id)];
        temp_options.body = JSON.stringify(temp_options.body);
        this.product_recommendations = [];
        const url = 'https://d16.infobaleen.com/api/v1/api-profiles/493/recommendations/items-to-items?use-cors';
        fetch(url, temp_options)
          .then(response => (response.json()))
          .then(data => {
            for (const el of data) {
              this.$store.dispatch('add_product_to_state', el)
                .then(res => (this.product_recommendations.push(res)))
            }
          })
        this.others_bought = [];
        const url2 = 'https://d16.infobaleen.com/api/v1/api-profiles/494/recommendations/items-to-items?use-cors';
        fetch(url2, temp_options)
          .then(response => response.json())
          .then(data => {
            for (const el of data) {
              this.$store.dispatch('add_product_to_state', el)
                .then(res => (this.others_bought.push(res)))
            }
          })
      },
      immediate: true,
    },
    
    opts: {
      deep: true,
      handler: function (options) {
        // console.log("gör saker")
        var temp_options = structuredClone(options);
        temp_options.body.Items = [String(this.product.Id)];
        temp_options.body = JSON.stringify(temp_options.body);
        this.product_recommendations = [];
        const url = 'https://d16.infobaleen.com/api/v1/api-profiles/493/recommendations/items-to-items?use-cors';
        fetch(url, temp_options)
          .then(response => (response.json()))
          .then(data => {
            for (const el of data) {
              this.$store.dispatch('add_product_to_state', el)
                .then(res => (this.product_recommendations.push(res)))
            }
          })
        this.others_bought = [];
        const url2 = 'https://d16.infobaleen.com/api/v1/api-profiles/494/recommendations/items-to-items?use-cors';
        fetch(url2, temp_options)
          .then(response => response.json())
          .then(data => {
            for (const el of data) {
              this.$store.dispatch('add_product_to_state', el)
                .then(res => (this.others_bought.push(res)))
            }
          })
      },
      immediate: true,
    }
  },
  components: {
    Recommendations,
    ProductDisplay
  },
}
</script>