import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'


Vue.config.productionTip = false


import catalogue from '../ff_items.json'
console.log(catalogue)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
  async created() {
    var titles = [];
    catalogue.forEach((el) => this.$store.dispatch('add_product_to_state', el));
    const cat = this.$store.state.all_products;
    const main_categories = [...new Set(cat.map(el => el.main_category))];
    for (const main_category of main_categories) {
      const main_cat_products = catalogue.filter(el => { return el.main_category == main_category });

      titles[main_category] = [...new Set(main_cat_products.map(el => el.subcategory_1))];
    }
    this.$store.state.categories = Object.entries(titles);
  }

}).$mount('#app')
