<template>
  <div class="pharmen">
    <v-app-bar app
               clipped-left
               color="#e0ebd1">
      <v-container>
        <v-row class="align-center">
          <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          <router-link to="/">

            <v-img src="@/assets/ff_small.png"
                   max-width="60"
                   contain></v-img>
          </router-link>
          <v-autocomplete v-model="selectedItem"
                          :filled="!searchBoxClosed"
                          style="padding-left: 10px"
                          rounded
                          clearable
                          auto-select-first
                          hide-no-data
                          :items="items"
                          :search-input.sync="search"
                          append-icon=""
                          placeholder="Sök bland våra produkter"
                          class="pt-5 shrink expanding-search"
                          :class="{ closed: searchBoxClosed && !selectedItem }"
                          :filter="() => true"
                          return-object
                          @keyup.enter="onSearch"
                          @keyup.esc="selectedItem = null; searchBoxClosed = true"
                          @focus="searchBoxClosed = false"
                          @blur="searchBoxClosed = true">
            <template v-slot:prepend-inner>
              <v-icon class="ml-n5">mdi-magnify</v-icon>
            </template>
            <template v-slot:item="data">
              <v-list-item-avatar tile>
                <v-img :src="data.item.image_link" contain/>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.title"></v-list-item-title>
                <v-list-item-subtitle>
                  {{ subTitle(data.item) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <!-- <v-expand-transition>
            <v-list v-if="selectedItem && selectedItem != ''">
              <v-list-item v-for="(field, i) in items" :key="i">
                <h1>{{ field }}</h1>
              </v-list-item>
            </v-list>
          </v-expand-transition> -->

          <v-spacer></v-spacer>
          <div>
            <LoginDropdown />
            <v-menu offset-y
                    left
                    :close-on-content-click="false"
                    max-width="358">
              <template v-slot:activator="{ on, attrs }">
                <v-badge :content="String(carted_count)"
                         overlap>
                  <v-btn icon
                         @click.stop="cart = !cart"
                         id="test"
                         v-bind="attrs"
                         v-on="on">
                    <v-icon>mdi-cart-outline</v-icon>
                  </v-btn>
                </v-badge>
              </template>
              <Cart />
            </v-menu>
          </div>
        </v-row>
        <div>

          <v-navigation-drawer app
                               clipped
                               v-model="drawer"
                               hide-overlay
                               dark
                               temporary>
            <MenuDrawer />
          </v-navigation-drawer>
        </div>
      </v-container>
    </v-app-bar>
  </div>

</template>

<style scoped lang="sass">
.v-input.expanding-search
	transition: max-width 0.5s
	&.closed
		max-width: 50px
</style>

<style scoped>
::v-deep(.v-text-field) {
  width: 40%;
}
</style>

<script>
import { mapGetters } from 'vuex';
import MenuDrawer from './MenuDrawer.vue'
import Cart from './Cart.vue';
import LoginDropdown from './LoginDropdown.vue';

export default {
  data: () => ({
    drawer: false,
    cart: false,
    userDialog: false,
    searchBoxClosed: true,
    selectedItem: null,
    search: null,
    items: []
  }),

  methods: {
    height() {
      if (this.searchExpand == true) {
        return "140%"
      }
      else {
        return "70%"
      }
    },
    subTitle(item) {
      return item.main_category + ' > ' + item.subcategory_1 + ' > ' + item.subcategory_2
    }
  },

  components: {
    MenuDrawer,
    Cart,
    LoginDropdown
  },

  computed: {
    ...mapGetters([
      'carted_count',
      'get_user'
    ]),
  },
  watch: {
    async search(v) {
      console.log('this get user',this.get_user)
      if (v) {
        let apiKey = 'bvLJUpa9s9HEuswOSShdvKZS4hQf9e'
        let url = `https://d16.infobaleen.com/api/v1/api-profiles/774/recommendations/user-to-items?use-cors`
        let resp = await fetch(url,{
          method: "POST",
          headers: {"Authorization": apiKey},
          body: JSON.stringify({
            Context:[
              {Name: "search_input", StringValue: v},
            ],
            User: this.get_user
          })}
        )
        let data = await resp.json()
        console.log('data',data)
        this.items = data.map(d => ({value: d.Id, text: d.format, ...this.$store.getters.get_item_by_id(d.Id)}))
      } else {
        this.items = []
      }
    },
    selectedItem (item) {
      this.searchBoxClosed = true;
      this.search = null;
      this.$router.push({
        name: 'prod',
        params: { 'id': String(item.value)}
      })
    }
  }
}
</script>
