<template>
  <v-system-bar class="pharmen"
                app
                height="35"
                color="#cfe1b7">
    <v-container>
      <v-row>
        <v-col class="text-center"
               cols="4"
               v-for="promise in promises"
               :key="promise">
          <span>
            {{ promise }}
          </span>
          <v-icon style="position: absolute;">
            mdi-check
          </v-icon>
        </v-col>
      </v-row>
    </v-container>
  </v-system-bar>
</template>

<script>
export default {
  data: () => ({
    promises: [
      "Infobaleen powered",
      "Personliga erbjudanden",
      "Fri frakt över 250 kr"
    ]
  })
}
</script>