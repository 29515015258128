<template>
  <v-app>
    <SysBar />
    <NavBar />
    <v-main>
      <v-sheet class="fill-height" color="#f4faec">

        <v-container>
          <router-view></router-view>
        </v-container>
      </v-sheet>
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import SysBar from './components/SysBar.vue'
import NavBar from './components/NavBar.vue'
import Footer from './components/Footer.vue'
export default {
  name: 'App',
  components: {
    SysBar,
    NavBar,
    Footer
  },
};
</script>
