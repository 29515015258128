import { render, staticRenderFns } from "./LandingPageRecommendations.vue?vue&type=template&id=005a169d&scoped=true&"
import script from "./LandingPageRecommendations.vue?vue&type=script&lang=js&"
export * from "./LandingPageRecommendations.vue?vue&type=script&lang=js&"
import style0 from "./LandingPageRecommendations.vue?vue&type=style&index=0&id=005a169d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "005a169d",
  null
  
)

export default component.exports