import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

class Product {
  constructor(data) {
    /* base parameters */
    this.Id = data.Id;
    this.title = data.title;
    this.main_category = data.main_category;
    this.subcategory_1 = data.subcategory_1;
    this.subcategory_2 = data.subcategory_2;
    this.image_link = data.image_link;
    this.price = Number(data.price);
    if (isNaN(data.sale_discount)) {
      this.sale_discount = 0;
      this.sale_price = this.price;
    }
    else {
      this.sale_price =  Math.round(Number(data.sale_price));
      this.sale_discount = Number(data.sale_discount) * 100;
    }
    this.is_on_sale = data.is_on_sale;
    this.carted = 0;
    this.tax = 0.2;
    this.liked = false;
    this.rating = Math.floor(Math.random() * 5 + 1);
  }
  update (product) {
    this.price = Number(product.price);
    if (isNaN(product.sale_discount)) {
      this.sale_discount = 0;
      this.sale_price = this.price;
    }
    else {
      this.sale_price =  Math.round(Number(product.sale_price));
      this.sale_discount = Number(product.sale_discount) * 100;
    }
    this.is_on_sale = product.is_on_sale;

  }
  toggle_liked() {
    this.liked = !this.liked;
  }
  total_price() {
    return this.carted * this.sale_price;
  }
}

const fetch_opts = {
  method: 'POST',
  headers: {
    'Authorization': 'aIFYKfA2yUS3RolDtEptpWTYoC7Uwg',
    'Content-Type': 'application/json'
  },
  body: {
    'Limit': 20,
    'User': '',
    'Items': [],
  }
}



export default new Vuex.Store({
  state: {
    user: '',
    all_products: [],
    infobaleen_data: {
      opts: fetch_opts
    },
    categories: [],
    images_by_main_categories: {
      'Clothing, Shoes & Jewelry': require('../assets/csj.webp'),
      'Sports & Outdoors': require('../assets/so.jpg'),
      'Leisure': require('../assets/l.jpg')
    },
    images_by_subcategories_1: {
      'Huvudvärk': require('../assets/huvudvärk.png'),
      'Gel och liniment': require('../assets/gelochliniment.png'),
      'Feber': require('../assets/feber.png'),
      'Mensvärk': require('../assets/mensvärk.png'),
      'Nästäppa': require('../assets/nästäppa.png'),
      'Öronbesvär': require('../assets/öronbesvär.png'),
      'Ögonbesvär': require('../assets/ögonbesvär.png'),
      'Ansiktsvård': require('../assets/ansiktsvård.png'),
      'Kroppsvård': require('../assets/kroppsvård.png'),
      'Multivitamin': require('../assets/multivitamin.png'),
      'C Vitamin': require('../assets/vitaminc.png'),
      'D Vitamin': require('../assets/vitamind.png'),
      'B Vitamin': require('../assets/vitaminb.png'),
      'Järn': require('../assets/järn.png'),
      'Tandborstar': require('../assets/tandborstar.png'),
      'Mens': require('../assets/mens.png'),
      'Sår': require('../assets/sår.png')
    }
  },
  getters: {
    /* Returns all Product-object with carted > 0 */
    carted_items(state) {
      return state.all_products.filter(obj => { return obj.carted > 0 });
    },
    /* Returns the mumber of carted items */
    carted_count(state) {
      const carted_items = state.all_products.filter(obj => { return obj.carted > 0 });
      return carted_items.reduce((sum, el) => sum + el.carted, 0);
    },
    /* Returns the number of *unique* carted items */
    unique_carted_count(state, getters) {
      const carted_items = getters.get_carted_items();
      return carted_items.length();
    },
    /* Returns the all unique main_category from state.all_products */
    main_categories(state) {
      return [...new Set(state.all_products.map(el => el.main_category))]
    },
    fetcher_opts(state) {
      /* state.infobaleen_data.opts.body = JSON.stringify(state.infobaleen_data.opts.body); */
      return state.infobaleen_data.opts;
    },
    cart_sum(state) {
      return state.all_products.reduce((sum, el) => sum + el.total_price(), 0);
    },
    taxed_sum(state, getters) {
      return (getters.cart_sum * 0.2).toFixed(1);
    },
    shipping_fee(state) {
      var sum = state.all_products.reduce((sum, a) => sum + a.carted * a.sale_price, 0);
      if (sum > 249) {
        return 0;
      }
      else {
        return 49;
      }
    },
    get_item_by_id: (state) => (Id) => {
      return state.all_products.find(el => el.Id == Id);
    },
    get_item_by_title: (state) => (title) => {
      return state.all_products.find(el => el.title == title);
    },
    items_by_main_category: (state) => (main_category) => {
      return state.all_products.filter(el => el.main_category == main_category);
    },
    items_by_subcategory_1: (state) => (subcategory_1) => {
      return state.all_products.filter(el => el.subcategory_1 == subcategory_1);
    },
    get_user(state) {
      return state.user;
    }
  },
  mutations: {
    /* INPUT:
              * product: dictionary
       OUTPUT:
              * Product
       DESC:
              Checks if a Product-object with the Id of 'product' already exists in state.all_products. If it doesn't, it creates a new object and appends it to the array */

    /* INPUT:
               * product: Product
               * n: How many of 'product' should be added to the cart
       OUTPUT:
               * None
       DESC:
              Adds n 'product's to the cart. Also updates 'Items' in the opts-body. */
    change_cart(state, {product, n}) {
      product.carted = Number(product.carted);
      product.carted += n;
      const Ids = state.all_products.filter(el => { return el.carted > 0 }).map(p => String(p.Id)); /* Unique? */
      var options = {
        method: 'POST',
        headers: {
          'Authorization': 'aIFYKfA2yUS3RolDtEptpWTYoC7Uwg',
          'Content-Type': 'application/json'
        },
        body: {
            'Limit': 20,
            'User': state.user,
            'Items': Ids,
          }
      };
      state.infobaleen_data.opts = options;
    },
    change_user(state, user) {
      const Ids = state.all_products.filter(el => { return el.carted > 0 }).map(p => String(p.Id));
      state.user = user;
      var options = {
        method: 'POST',
        headers: {
          'Authorization': 'aIFYKfA2yUS3RolDtEptpWTYoC7Uwg',
          'Content-Type': 'application/json'
        },
        body: {
            'Limit': 20,
            'User': state.user,
            'Items': Ids,
          }
      };
      state.infobaleen_data.opts = options;
    }
  },
  actions: {
    add_product_to_state({state}, product) {
      var rp = state.all_products.find(el => el.Id == product.Id);
      console.log("inne")
      if (rp) {
        rp.update(product);
        return rp;
      }
      else {

        rp = new Product(product);
        state.all_products.push(rp);
        return rp;
      }
    },




  }
})
