<template>

  <v-dialog :close-on-content-click="false"
            max-width="358"
            v-model="userDialog">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon
             id="test"
             v-bind="attrs"
             v-on="on">
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>
    <v-form class="pharmen">
      <v-card class="pa-4">
        <v-card-title class="mb-0 pb-0">Ange en användare</v-card-title>
        <v-card-text class="mt-0 pt-0">(Används nu: {{$store.state.user}})</v-card-text>
        <v-card-actions>
          <v-text-field v-model="userId"
                        label="User ID"
                        outlined
                        @keydown.enter.prevent="on_btn_click"
                        autofocus></v-text-field>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="on_btn_click">ok</v-btn>
        </v-card-actions>

      </v-card>
    </v-form>
  </v-dialog>

</template>

<script>
export default {
  data: () => ({
    userId: "",
    userDialog: false,
  }),
  methods: {
    on_btn_click() {
      this.$store.commit('change_user', this.userId);
      this.userDialog = false;
      this.userId = '';
    }
  }
}
</script>